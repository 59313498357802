import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"

import { Typography } from "@mui/material"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author

  return (
    <Card sx={{ display: "flex" }}>
      <CardMedia sx={{ width: 120 }}>
        <div className="bio">
          <StaticImage
            className="bio-avatar"
            layout="fixed"
            formats={["auto", "webp", "avif"]}
            src="../images/me_3000_3000.jpg"
            width={100}
            height={100}
            quality={95}
            alt="Profile picture"
          />
        </div>
      </CardMedia>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          {author?.name && (
            <>
              <Typography variant="body1">
                Developed and maintained by <strong>{author.name}</strong>
                {". "}
                <br />
                {author?.summary || null}
              </Typography>
              <br />
              <Typography variant="body2">
                Not a financial advisor :)
              </Typography>
            </>
          )}
        </CardContent>
      </Box>
    </Card>
  )
}

export default Bio
