import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import Link from "@mui/material/Link"

const About = () => {
  return (
    <>
      <Layout>
        <Seo
          title="About"
          description="About me page for loanprepayplanner.com"
        />
        <h1>About</h1>
        <p>
          We have built the loanprepayplanner.com as a Commercial service. This
          web application is intended for use as is.
        </p>
        <p>
          This is a Progressive Web Application (PWA), so you can install this
          application in your computer and mobile device with ease.{" "}
          <Link
            target="_blank"
            href="https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DAndroid&oco=0"
            underline="hover"
          >
            Learn More on installing as application
          </Link>
        </p>
        <h2>Our Vision</h2>
        <blockquote>
          <p>
            The vision behind this application is to help you finish your
            mortgage loan by planning regular prepayments and compare the
            effects of amount paid in advance against paying the same amount
            over entire tenure of loan
          </p>
        </blockquote>
        <Bio />
      </Layout>
    </>
  )
}

export default About
